/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const IS_BOY_FIRST = true; //change to false for switch broadcast text

export const GIRL_NAME = 'Lucella Rikasiwi';
export const GIRL_NAME_SHORT = 'Lucella';
export const GIRL_FULL_NAME = GIRL_NAME;
export const GIRL_FATHER_NAME = `Martinus Sukardi`;
export const GIRL_MOTHER_NAME = `Margaretta Dwi Wahyuningsih`;
export const GIRL_PARENT_NAME = {
    en: `The daughter of Mr. ${GIRL_FATHER_NAME} <br />and Mrs. ${GIRL_MOTHER_NAME}`,
    id: `Putri dari Bapak ${GIRL_FATHER_NAME} <br />dan Ibu ${GIRL_MOTHER_NAME}`
};

// --- boy sections
export const BOY_NAME = 'Stefanus Oswald Sandy';
export const BOY_NAME_SHORT = 'Oswald';
export const BOY_FULL_NAME = BOY_NAME;
export const BOY_FATHER_NAME = `Hendrikus Wijaya`;
export const BOY_MOTHER_NAME = `Elise Nurhayati`;
export const BOY_PARENT_NAME = {
    en: `The son of Mr. ${BOY_FATHER_NAME} <br />and Mrs. ${BOY_MOTHER_NAME}`,
    id: `Putra dari Bapak ${BOY_FATHER_NAME} <br />dan Ibu ${BOY_MOTHER_NAME}`
};

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}` 
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'discopapaya';
export const IG_GIRL = 'uulyong';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/SEO_eoFIxWxBg.jpg?updatedAt=1686027901048`;
export const SEO_URL = 'https://oswaldlucella.com/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE}`;
export const SEO_DESCRIPTION = {
  en: `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`,
  id : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`
};

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1688785200;
export const EPOCH_RESEPSI_START = 1688785200;
export const EPOCH_END_EVENT = 1688817600	;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
const DATE_AKAD = new Date('2023-07-08');
const DATE_RESEPSI = new Date('2023-07-08');

export const WEDDING_AKAD_DATE = {
  id : dayjs(DATE_AKAD).locale('id').format('DD MMMM YYYY'),
  en : dayjs(DATE_AKAD).locale('en').format('DD MMMM YYYY')
};

export const WEDDING_AKAD_TIME = '10.00 – 12.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Kapel Villa Deo Gratias';
export const WEDDING_AKAD_LOC_ROAD = 'Jl. Vila Istana Bunga, Parongpong, Bandung Barat 40559';
export const WEDDING_AKAD_FULLDATE = {
  id : dayjs(DATE_AKAD).locale('id').format('dddd, DD MMMM YYYY'),
  en : dayjs(DATE_AKAD).locale('en').format('dddd, DD MMMM YYYY')
};

export const WEDDING_RESEPSI_FULLDATE = {
  id : dayjs(DATE_AKAD).locale('id').format('dddd, DD MMMM YYYY'),
  en : dayjs(DATE_AKAD).locale('en').format('dddd, DD MMMM YYYY')
};

export const WEDDING_RESEPSI_TIME = '16.00 WIB – Finish';
export const WEDDING_RESEPSI_LOC_NAME = 'Dream Belle';
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. Nagrak Kulon No. 67, Lembang, Bandung Barat 40391`;
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD.MM.YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://oswaldlucella.com/';
export const BROADCAST_WEDDING_LOCATION = `Dream Belle`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://g.co/kgs/1fpijw`;
export const GOOGLE_MAPS_ADDRESS = `Showed in the wedding details`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Lucille - LIttle Richard`;
export const SOUND_URL = 'https://youtu.be/K4fNqoHzeO4';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/xQXYjtyjyL4';
export const YOUTUBE_LINK = 'https://youtu.be/xQXYjtyjyL4';
export const DISABLE_IFRAME = false;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME_RSVP = `https://script.google.com/macros/s/AKfycbzhs27WiSF-r3Fzg_1jK7p2oJenvERXANvEqWRllaIZScU0OoQtI8GOCHfWMVhGaH67zg/exec`;
export const API_RSVP_TABLE = 'rsvp';
export const API_HOSTNAME = API_HOSTNAME_RSVP;
export const API_TABLE_NAME = 'wishlist';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = `https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!`;