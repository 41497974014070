// link generator features
export const ENABLE_CUSTOM_LINK = true;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_GENERATOR_INVITATION = true;
export const ENABLE_SHIFT_TIME_RECEPTION = false;
export const ENABLE_DIFFERENT_GUEST_RSVP = true; // change to `true` if any different option guest in rsvp 

// giftcard features
export const ENABLE_SEND_GIFT = true;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// QR Code features
export const ENABLE_QR_INVITATION = false;
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;

// special features
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const ENABLE_DARKMODE_NOTIFICATION = true;
export const MUSLIM_INVITATION = false; // change to `true` if you want to use muslim wording
export const DEFAULT_LANGUAGE = 'en'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = true;
export const ENABLE_QUOTES = false; // change to `true` if any quotes need to add in Invitation
export const ENABLE_COLLABORATION = true; // change to `true` if any collaboration with Invitato partner

// global features
export const ENABLE_RSVP = true;
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_LIVE_STREAMING = false;
export const ENABLE_GIFT_CARD = true;
export const ENABLE_LOVE_STORY = false;
export const ENABLE_WEDDING_FILTER = false;
export const ENABLE_VIDEO_PREWEDDING = true; // if live streaming false, you can use this
export const ENABLE_VIDEO_PREWEDDING_ALTERNATIVE = false; // if live streaming true, you can use this